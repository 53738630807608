import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../../components/layout';
import { item, itemImage, itemList, itemTitle } from './projects.module.css';
import { Seo } from '../../components/seo';

const BlogPage = ({ data }) => {
    return (
        <Layout pageTitle='Projects'>
            <div className={itemList}>
                {data.allMdx.nodes.map((node) => (
                    <article className={`${node.frontmatter.slug} ${item}`} style={{ gridArea: `${node.frontmatter.slug}` }} key={node.id}>
                        <Link to={`/projects/${node.frontmatter.slug}`}>
                            <h2 className={itemTitle}>{node.frontmatter.title}</h2>
                            <GatsbyImage className={itemImage} image={getImage(node.frontmatter.hero_image)} alt={node.frontmatter.hero_image_alt} />
                        </Link>
                    </article>
                ))}
            </div>
        </Layout>
    );
};

export const query = graphql`
    query {
        allMdx(
            sort: {fields: frontmatter___order, order: DESC}
            filter: {frontmatter: {type: {eq: "project"}}}
          ) {
            nodes {
              frontmatter {
                title
                date(formatString: "MMM D, YYYY")
                slug
                order
                hero_image_alt
                hero_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              id
            }
          }
    }
`;

export default BlogPage;

export const Head = () => <Seo title='Projects' />;
